import { useStaticQuery, graphql } from "gatsby"

export const useWhatIs4kQuery = () => {


    const data = useStaticQuery(graphql`
    query WhatIs4kQuery {
        wpPage(title: {eq: "WHAT IS 4K SCORE"}) {
        ACF_WhatIs4KPage {
            whatis4kHero {
                image {
                    publicUrl
                }
                text
                tagline
            }

            whatis4kTagline1
            whatis4kImageBlock {
                publicUrl
            }
            whatis4kTestimonials {
                title
                testimonial1
                testimonial2
            }
            whatis4kMetaData {
                metaTitle
                metaDescription
            }
        }
        }
    }     
    `)
    return data.wpPage.ACF_WhatIs4KPage;
}