import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/what-is-the-4kscore/Hero";
import BlockOne from "../components/what-is-the-4kscore/BlockOne";
import BlockThree from "../components/what-is-the-4kscore/BlockThree";
import { useWhatIs4kQuery } from "../hooks/useWhatIs4kQuery";
import Helmet from "react-helmet";

const WhatIsThe4kscorePage = () => {
    const data = useWhatIs4kQuery();
    return (
        <Layout>
            <Hero data={data.whatis4kHero} />
            <BlockOne data={data} />
            <BlockThree data={data} />
            <Helmet>
                <title>{data.whatis4kMetaData.metaTitle}</title>
                <meta name="description" content={data.whatis4kMetaData.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default WhatIsThe4kscorePage;