import React from 'react';
import Slider from "react-slick";

class BlockThree extends React.Component {

    constructor(props) {
        super(props);
        this.sliderSettings = { slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 4500, adaptiveHeight: true };
    }

    render() {
        return (

            <div>
                <div className="span12 widget-span widget-type-cell full-width">
                    <div className="row-fluid span12">
                        <div><img src={this.props.data.whatis4kImageBlock.publicUrl} alt="" /></div>
                    </div>

                </div>


                <div className="row-fluid-wrapper row-depth-1 row-number-20 ">
                    <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                            <div className="row-fluid-wrapper row-depth-1 row-number-21 ">
                                <div className="row-fluid ">
                                    <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                        <div className="row-fluid-wrapper row-depth-1 row-number-22 ">
                                            <div className="row-fluid ">
                                                <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                                    <div className="row-fluid-wrapper row-depth-1 row-number-23 ">
                                                        <div className="row-fluid ">
                                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                <div id="hs_cos_wrapper_module_15329929570983191" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15329929570983191_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: this.props.data.whatis4kTestimonials.title }}></h2></span></div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row-fluid-wrapper row-depth-1 row-number-24 ">
                                                        <div className="row-fluid ">
                                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                <div id="hs_cos_wrapper_module_1541682883368443" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                                    <Slider {...this.sliderSettings}>
                                                                        <div dangerouslySetInnerHTML={{ __html: this.props.data.whatis4kTestimonials.testimonial1 }}>
                                                                        </div>
                                                                        <div dangerouslySetInnerHTML={{ __html: this.props.data.whatis4kTestimonials.testimonial2 }}>
                                                                        </div>
                                                                    </Slider>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row-fluid-wrapper row-depth-1 row-number-25 ">
                                            <div className="row-fluid ">
                                                <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "90px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                    <div id="hs_cos_wrapper_module_15329929348913176" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-26 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "40px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_15329929391233180" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlockThree;